import React, { useState } from "react";
import homedata from "../../data/home.json";
import axios from "axios";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import WhatsAppIcons from "./whatsapp";

const Contact = () => {
  const [cargando, setCargando] = useState(false);

  const envioDeCorreo = (e) => {
    e.preventDefault(); // Previene la actualización de la página

    // Obtén el formulario y sus campos
    const form = e.target.form;
    const nombre = form.name.value;
    const correo = form.email.value;
    const subject = form.subject.value;
    const mensaje = form.message.value;

    //Cambiar estos datos para cada pagina y su respectivo contacto
    const nombreProp = "CIGE";
    const correoProp = "susan.larios@gmail.com.com";
    const pagina = "https://cige.com.mx/#/";

    const data = {
      nombre,
      correo,
      mensaje,
      nombreProp,
      correoProp,
      pagina,
    };

    // Realizar la solicitud POST utilizando Axios
    setCargando(true);
    axios
      .post("https://taeconta.com/api/public/api/correos/publicos", data)
      .then((response) => {
        console.log("Correo enviado con éxito:", response.data);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Correo enviado exitosamente",
          showConfirmButton: false,
          timer: 1500,
        });
        setCargando(false);
        // clearState(e)
      })
      .catch((error) => {
        console.error("Error al enviar el correo:", error);
        Swal.fire(
          "Error al enviar el correo",
          error.message + ", " + error.response.data.message,
          "error"
        );
        setCargando(false);
        // clearState(e)
      });
  };

  let contactInfo = homedata.contact;
  let sectionHeadingInfo = contactInfo.sectionHeading;
  let publicUrl = process.env.PUBLIC_URL;
  let inlineStyle = {
    backgroundImage: "url(" + publicUrl + contactInfo.bgImg + ")",
  };

  return (
    <section
      id="contact"
      className="pt100 pb100 bg-parallax"
      style={inlineStyle}
    >
      <WhatsAppIcons />
      <div className="color-overlay opacity-8"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="contact-block text-center">
              <div className="col-sm-12">
                <h3>{sectionHeadingInfo.title}</h3>
                <p className="subtitle">{sectionHeadingInfo.desc}</p>
                <a href="tel:8713814929" className="subtitle" style={{color: 'gray'}}>
                  Teléfono: +52 55 1804 8173
                </a>
              </div>
              
            
              <form id="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      name="name"
                      className="contact-name form-control input-box"
                      id="contact-name"
                      type="text"
                      placeholder="Su nombre"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      name="email"
                      className="contact-email form-control input-box"
                      id="contact-email"
                      type="email"
                      placeholder="Su correo"
                      required
                    />
                  </div>
                  <div className="col-sm-12">
                    <input
                      name="subject"
                      className="contact-subject form-control input-box"
                      id="contact-subject"
                      type="text"
                      placeholder="Asunto"
                      required
                    />
                  </div>
                  <div className="col-sm-12">
                    <textarea
                      name="message"
                      className="contact-message form-control textarea-box"
                      id="contact-message"
                      placeholder="Mensaje"
                      required
                    ></textarea>
                  </div>
                  <div className="col-sm-12">
                    <button className="primary-btn" onClick={envioDeCorreo}>
                      {cargando ? (
                        <ReactLoading type="cylon" />
                      ) : (
                        <>
                          <i
                            className="fa fa-paper-plane"
                            aria-hidden="true"
                          ></i>{" "}
                          {contactInfo.submitBtnLabel}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
